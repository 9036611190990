import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// Switch is now Routes

import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Layout from './components/Layout/Layout'
import Enrollment from './pages/Enrollment/Enrollment'

import SignupEntrepreneurship from './pages/SignUpEntrepreneurship/SignupEntrepreneurship';
import SignupEntrepreneurshipTechnology from './pages/SignupEntrepreneurshipTechnology/SignupEntrepreneurshipTechnologry';
import SignupEntrepreneurshipTechnologyTrimestral from './pages/SignupEntrepreneurshipTechnologyTrimestral/SignupEntrepreneurshipTechnologyTrimestral'
import SignupDemoClass from './pages/SignupDemoClass/SignupDemoClass'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Building from './pages/Building/Building'
import ImageLoader from './components/ImageLoader/ImageLoader';
// import logo from './logo.svg';
// import './App.css';
import './pages/HomePage/HomePage.scss'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="*" element={<Building />}></Route> */}
          <Route element={<Layout />} >
            <Route exact path='/' element={<Enrollment/>} />

            {/* <Route exact path="/plans/:id" element={<Plans />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-technology" element={<SignupEntrepreneurshipTechnology />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology/:id" element={<SignupEntrepreneurshipTechnology />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology/:id" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-trimestral" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral/:id" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-trimestral/:id" element={<Building />} />
            {/* <Route exact path="/demo-class" element={<SignupDemoClass />} /> */}
            <Route exact path="/demo-class" element={<Building />} />
            <Route exact path="/building" element={<Building />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>


    // </Route>
    
    // <AuthProvider>
    //   <BrowserRouter>
    //     <Switch>
    //       <Route exact path="/" component={Home}></Route>
    //     </Switch>
    //   </BrowserRouter>
    // </AuthProvider>
  );
}

export default App;
